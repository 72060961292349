<template>
  <section class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Články - Typy - Nabídka - Přidat</strong>
      </div>
      <action-tools :router-options="routerOptions"></action-tools>
    </div>
    <div class="card-body">
      <div class="table-operations">
        <language-tab></language-tab>
      </div>
      <a-form :form="form" @submit="handleSubmit">
        <a-form-item
          label="Název"
          :validate-status="error('ale_name') ? 'error' : ''"
          :help="error('ale_name') || ''"
        >
          <a-input placeholder="Název" v-decorator="['ale_name', {rules: [{max: 60, message: 'Název nemůže být delší než 100 znaků!'}, {required: true, message: 'Název musí být vyplněn!'}]}]"/>
        </a-form-item>

        <my-form-image-item
          title="Obrázek"
          subject="Nabídka"
          decorator-name="existing"
          :image="thumbImage"
          :image-next-count="thumbImageNextCount"
          :images="thumbImages"
          :on-image-switch-change="onThumbImageSwitchChange"
          :on-next-images-click="onNextThumbImagesClick"
          :form="form"
          :required="true"
        ></my-form-image-item>

        <a-form-item
          label="Nadpis odznaku"
          :validate-status="error('badge_title') ? 'error' : ''"
          :help="error('badge_title') || ''"
        >
          <a-input placeholder="Nadpis odznak" v-decorator="['badge_title', {rules: [{required: form.getFieldValue('badge_content') !== undefined && form.getFieldValue('badge_content').trim().length > 0, message: 'Nadpis odznak musí být vyplněn!'}]}]"/>
        </a-form-item>

        <a-form-item
          label="Obsah odznaku"
          :validate-status="error('badge_content') ? 'error' : ''"
          :help="error('badge_content') || ''"
        >
          <a-input placeholder="Obsah odznaku" v-decorator="['badge_content', {rules: [{required: form.getFieldValue('badge_title') !== undefined && form.getFieldValue('badge_title').trim().length > 0, message: 'Obsah odznaku musí být vyplněn!'}]}]"/>
        </a-form-item>

        <a-form-item
          label="Nadpis tlačítka"
          :validate-status="error('button_title') ? 'error' : ''"
          :help="error('button_title') || ''"
        >
          <a-input placeholder="Nadpis tlačítka" v-decorator="['button_title', {rules: [{required: form.getFieldValue('button_link') !== undefined && form.getFieldValue('button_link').trim().length > 0, message: 'Nadpis tlačítka musí být vyplněn!'}]}]"/>
        </a-form-item>

        <a-form-item
          label="Odkaz tlačítka"
          :validate-status="error('button_link') ? 'error' : ''"
          :help="error('button_link') || ''"
        >
          <a-input placeholder="Odkaz tlačítka" v-decorator="['button_link', {rules: [{required: form.getFieldValue('button_title') !== undefined && form.getFieldValue('button_title').trim().length > 0, message: 'Odkaz tlačítka musí být vyplněn!'}]}]"/>
        </a-form-item>

        <a-form-item
          label="Nadpis tučně"
          :validate-status="error('ale_title') ? 'error' : ''"
          :help="error('ale_title') || ''"
        >
          <a-input placeholder="Nadpis tučně" v-decorator="['ale_title', {rules: [{max: 60, message: 'Nadpis tučně nemůže být delší než 60 znaků!'}]}]"/>
        </a-form-item>

        <a-form-item
          label="Nadpis kurzivou"
          :validate-status="error('ale_title_thin') ? 'error' : ''"
          :help="error('ale_title_thin') || ''"
        >
          <a-input placeholder="Nadpis kurzivou" v-decorator="['ale_title_thin', {rules: [{max: 60, message: 'Nadpis kurzivou nemůže být delší než 60 znaků!'}]}]"/>
        </a-form-item>

        <a-form-item
          label="Popisek"
          :validate-status="error('ale_description') ? 'error' : ''"
          :help="error('ale_description') || ''"
        >
          <a-input
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 10 }"
            placeholder="Popisek"
            v-decorator="['ale_description']"
          />
        </a-form-item>

        <a-form-item label="Zveřejnit?">
          <a-checkbox v-decorator="['ale_is_active', { valuePropName: 'checked', initialValue: true }]"/>
        </a-form-item>

        <a-form-item label="Přidat mezeru pod článek?">
          <a-checkbox v-decorator="['gap', { valuePropName: 'checked', initialValue: true }]"/>
        </a-form-item>

        <div class="form-actions">
          <a-button html-type="submit" type="primary" :disabled="loading || hasErrors(form.getFieldsError()) || !this.isThumbImageSelected(false)">Přidat</a-button>
        </div>
      </a-form>
    </div>
  </section>
</template>

<script>
import LanguageTab from '@/components/MyComponents/LanguageTab'
import ActionTools from '@/components/MyComponents/ActionTools'
import { hasErrors } from '../../../../services/forms'
import imageSizeMixin from '@/components/MyComponents/Gallery/image-size-mixin'
import myFormImageItem from '@/components/MyComponents/Gallery/my-form-image-item'
import thumbImageMixin from '@/components/MyComponents/Gallery/thumb-image-mixin'

export default {
  components: {
    ActionTools, LanguageTab, myFormImageItem,
  },
  mixins: [imageSizeMixin, thumbImageMixin],
  beforeCreate() {
    this.form = this.$form.createForm(this)
  },
  data() {
    return {
      hasErrors,
      loading: false,
      routerOptions: [
        {
          icon: 'bars',
          theme: 'outlined',
          to: '/article/type/doy',
          title: 'Seznam',
        },
      ],
      item: {
        languages: [],
      },
      formItemLayout: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 20 },
        },
      },
      loaded: [],
    }
  },
  computed: {
    language: function () {
      return this.$store.getters['language/active']
    },
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.handleChange(this.language)
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('article/post', this.getData())
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    getData() {
      return {
        ate_type: 'DOY',
        languages: this.item.languages.filter(x => x.ale_name !== undefined && x.ale_name.trim().length > 0).map(x => {
          return {
            lge_id: x.lge_id,
            ale_title_thin: x.ale_title_thin !== undefined ? x.ale_title_thin : null,
            ale_content: x.ale_content,
            ale_description: x.ale_description !== undefined ? x.ale_description : null,
            ale_title: x.ale_title !== undefined ? x.ale_title : null,
            ale_name: x.ale_name !== undefined ? x.ale_name : null,
            ale_is_active: x.ale_is_active,
          }
        }),
      }
    },
    handleChange(oldLanguage) {
      const foundObj = { article: false }
      const newObj = {
        article: {
          lge_id: oldLanguage,
          ale_title_thin: this.form.getFieldValue('ale_title_thin'),
          ale_content: {
            gap: this.form.getFieldValue('gap'),
            image: this.isThumbImageSelected(false) ? this.thumbImage.selectedOne : null,
            badge: { title: this.form.getFieldValue('badge_title'), content: this.form.getFieldValue('badge_content') },
            button: { title: this.form.getFieldValue('button_title'), link: this.form.getFieldValue('button_link') },
          },
          ale_description: this.form.getFieldValue('ale_description'),
          ale_title: this.form.getFieldValue('ale_title'),
          ale_name: this.form.getFieldValue('ale_name'),
          ale_is_active: this.form.getFieldValue('ale_is_active'),
        },
      }
      this.item.languages.some((value, key) => {
        if (value.lge_id === oldLanguage) {
          this.item.languages[key] = newObj.article
          foundObj.article = true
          return true
        }
      })
      if (!foundObj.article) {
        this.item.languages.push(newObj.article)
      }
    },
    error(fieldName) {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched(fieldName) && getFieldError(fieldName)
    },
  },
  watch: {
    language(newValue, oldValue) {
      if (oldValue !== null) {
        this.handleChange(oldValue)
      }
      if (newValue !== null) {
        const foundObj = { article: this.item.languages.find(x => x.lge_id === newValue) }
        let newObj
        if (foundObj.article === undefined) {
          newObj = {
            ale_title_thin: undefined,
            ale_description: undefined,
            ale_title: undefined,
            ale_name: undefined,
            ale_is_active: true,
            gap: true,
            existing: undefined,
            badge_title: undefined,
            badge_content: undefined,
            button_title: undefined,
            button_link: undefined,
          }
          this.thumbImage.selectedOne = undefined
        } else {
          newObj = {
            ale_title_thin: foundObj.article.ale_title_thin,
            gap: foundObj.article.ale_content.gap,
            ale_description: foundObj.article.ale_description,
            ale_title: foundObj.article.ale_title,
            ale_name: foundObj.article.ale_name,
            ale_is_active: foundObj.article.ale_is_active,
            existing: foundObj.article.ale_content.image === undefined ? undefined : foundObj.article.ale_content.image.iae_id,
            badge_title: foundObj.article.ale_content.badge !== undefined && foundObj.article.ale_content.badge !== null ? foundObj.article.ale_content.badge.title : undefined,
            badge_content: foundObj.article.ale_content.badge !== undefined && foundObj.article.ale_content.badge !== null ? foundObj.article.ale_content.badge.content : undefined,
            button_title: foundObj.article.ale_content.button !== undefined && foundObj.article.ale_content.button !== null ? foundObj.article.ale_content.button.title : undefined,
            button_link: foundObj.article.ale_content.button !== undefined && foundObj.article.ale_content.button !== null ? foundObj.article.ale_content.button.link : undefined,
          }
          this.thumbImage.selectedOne = foundObj.article.ale_content.image === undefined ? undefined : foundObj.article.ale_content.image
        }
        this.form.getFieldDecorator('existing')
        this.form.setFieldsValue(newObj)
        this.thumbImage.trigger = !this.thumbImage.trigger
        this.form.validateFields()
      }
    },
  },
}
</script>

<style lang="scss" module>
  @import "./style.module.scss";
</style>
